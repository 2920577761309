import React,{ Component } from "react";
import { Route,Switch } from "react-router-dom";
import DashboardContainer from '../../blocks/dashboard/src/DashboardContainer.web'
import EmailAccountSignUp from "../../blocks/email-account-login/src/EamilAccountSignUp.web";
import EmailAccountLogin from "../../blocks/email-account-login/src/EmailAccountLogin.web"
class RouterConfig extends Component{

    render(){
       
        return(
            <Switch>
                <Route exact path="/">
                <EmailAccountSignUp />
                </Route>
                <Route exact path="/login">
                <EmailAccountLogin />
                </Route>
                <Route  path="/home">
                <DashboardContainer />
                </Route>
               
            </Switch>
        )
    }
}
export default RouterConfig