// Customizable Area Start
//@ts-nocheck
import React from "react";
import {
    Container,
    Button,
    Typography,
    Grid,
    Box,
    Avatar
} from "@material-ui/core";
import EmailAccountSignUpController, {
    Props,
} from "./EmailAccountSignUpController.web";
import { loginImage, background, logo } from "./assets";
import { createStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { withRouter } from "react-router";
import Checkbox from "@material-ui/core/Checkbox";
import Snackbar from '@material-ui/core/Snackbar';
const Action = ({ handleSnakbarClose }) => {
    return (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={handleSnakbarClose}>
                Okay
            </Button>

        </React.Fragment>
    )
};

class EmailAccountSignUp extends EmailAccountSignUpController {
    constructor(props: Props) {
        super(props);
    }
    handleChangeInputs = (e: any) => {
        const { name, value }: { name: any; value: any } = e.target
        let data: any = this.state.signupObj
        data[name] = value;
        this.setState({
            signupObj: data, errors: {
                ...this.state.errors,
                [name]: this.validateSignUpFields(e.target.name, e.target.value)
            },
            fields: {
                ...this.state.fields,
                [e.target.name]: e.target.value,

            }
        })
    }
    handleChangeCheckbox = () => {
        this.setState({ checkbox: !this.state.checkbox })
    }
    render() {
        const { classes } = this.props;
        const labels = { inputProps: { 'aria-label': 'Checkbox demo' } };
        return (
            <Grid item xs={12} style={{ overflowY: "hidden" }}>
                <Grid container direction='row' justifyContent='center'  >
                    <Grid item xs={10} md={7} className={classes.contentSignUp}>
                        <Container maxWidth="xl" >
                            <Grid container justifyContent='center' spacing={3}>
                                <Grid item xs={10} style={{ display:'flex', position: 'relative', left: '0px', marginTop: '12px', marginRight:'40px' }}>
                                    <Avatar src={logo} />
                                    <Typography className={classes.signntexts}>Sustainext</Typography>
                                </Grid>
                                <Grid item xs={10}
                                style={{
                                    padding:'6px'
                                }}
                                >
                                    <Typography className={classes.signinlabel} >Register</Typography>
                                </Grid>

                                <Grid item>
                                    <Grid container spacing={3} justifyContent="center">

                                        <Grid item xs={5}
                                         style={{
                                            padding:'6px'
                                        }}
                                        >
                                            <TextField
                                                fullWidth
                                                label="First Name"
                                                id="outlined-size-small"
                                                variant="outlined"
                                                size="medium"
                                                name="firstname"
                                                onChange={(e) => this.handleChangeInputs(e)}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                        outlined: classes?.outlinedLabels,
                                                        root: classes?.lableroots
                                                    }
                                                }}
                                                InputProps={{
                                                    classes: {
                                                        input: classes?.input,
                                                        root: classes?.inputfieledrootSignUp
                                                        ,
                                                    },
                                                    notched: false,
                                                }}
                                                FormHelperTextProps={{
                                                    className: classes.helperText
                                                  }}
                                                helperText={this.state.errors.firstname}
                                            />
                                        </Grid>
                                        <Grid item xs={5}
                                         style={{
                                            padding:'6px'
                                        }} >
                                            <TextField
                                                fullWidth
                                                label="Last Name"
                                                id="outlined-size-small"
                                                variant="outlined"
                                                size="medium"
                                                name='lastname'
                                                onChange={(e) => this.handleChangeInputs(e)}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                        outlined: classes?.outlinedLabels,
                                                        root: classes?.lableroots
                                                    }
                                                }}
                                                InputProps={{
                                                    classes: {
                                                        input: classes?.input,
                                                        root: classes?.inputfieledrootSignUp,
                                                    },
                                                    notched: false,
                                                }}
                                                FormHelperTextProps={{
                                                    className: classes.helperText
                                                  }}
                                                helperText={this.state.errors.lastname}
                                            />
                                        </Grid>
                                        <Grid item xs={5} 
                                         style={{
                                            padding:'6px'
                                        }}
                                        >
                                            <TextField
                                                fullWidth
                                                label="Email ID"
                                                id="outlined-size-small"
                                                variant="outlined"
                                                size="medium"
                                                name='email'
                                                onChange={(e) => this.handleChangeInputs(e)}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                        outlined: classes?.outlinedLabels,
                                                        root: classes?.lableroots
                                                    }
                                                }}
                                                InputProps={{
                                                    classes: {
                                                        input: classes?.input,
                                                        root: classes?.inputfieledrootSignUp,
                                                    },
                                                    notched: false,
                                                }}
                                                FormHelperTextProps={{
                                                    className: classes.helperText
                                                  }}
                                                helperText={this.state.errors.email}
                                            />
                                        </Grid>
                                        <Grid item xs={5}
                                         style={{
                                            padding:'6px'
                                        }}
                                        >
                                            <TextField
                                                fullWidth
                                                label="Phone Number"
                                                placeholder="+1"
                                                id="outlined-size-small"
                                                variant="outlined"
                                                size="medium"
                                                name='phonenumber'
                                                inputProps={{maxLength:13}}
                                                onChange={(e) => this.handleChangeInputs(e)}
                                                
                                                InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                        outlined: classes?.outlinedLabels,
                                                        root: classes?.lableroots
                                                    }
                                                }}
                                                InputProps={{
                                                    classes: {
                                                        input: classes?.input,
                                                        root: classes?.inputfieledrootSignUp,
                                                    },
                                                   
                                                    notched: false,
                                                }}
                                                FormHelperTextProps={{
                                                    className: classes.helperText
                                                  }}
                                                helperText={this.state.errors.phonenumber}
                                            />
                                        </Grid>
                                        <Grid item xs={5} 
                                         style={{
                                            padding:'6px'
                                        }}
                                        >
                                            <TextField
                                                fullWidth
                                                label="Password"
                                                type="Password"
                                                id="outlined-size-small"
                                                variant="outlined"
                                                size="medium"
                                                name='password'
                                                onChange={(e) => this.handleChangeInputs(e)}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                        outlined: classes?.outlinedLabels,
                                                        root: classes?.lableroots
                                                    }
                                                }}
                                                InputProps={{
                                                    classes: {
                                                        input: classes?.input,
                                                        root: classes?.inputfieledrootSignUp,
                                                    },
                                                    notched: false,
                                                }}
                                                FormHelperTextProps={{
                                                    className: classes.helperText
                                                  }}
                                                helperText={this.state.errors.password}
                                            />
                                        </Grid>
                                        <Grid item xs={5}
                                         style={{
                                            padding:'6px'
                                        }}
                                        >
                                            <TextField
                                                fullWidth
                                                label="Confirm Password"
                                                type="Password"
                                                id="outlined-size-small"
                                                variant="outlined"
                                                size="medium"
                                                name='conformpassword'
                                                onChange={(e) => this.handleChangeInputs(e)}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                        outlined: classes?.outlinedLabels,
                                                        root: classes?.lableroots
                                                    }
                                                }}
                                                InputProps={{
                                                    classes: {
                                                        input: classes?.input,
                                                        root: classes?.inputfieledrootSignUp,
                                                    },
                                                    notched: false,
                                                }}
                                                FormHelperTextProps={{
                                                    className: classes.helperText
                                                  }}
                                                helperText={this.state.errors.conformpassword}
                                            />
                                        </Grid>

                                    </Grid>
                                </Grid>

                                <Grid item xs={10} 
                                style={{
                                    padding:'5px',
                                    marginLeft:'-19px'
                                }}>
                                    <Box display='flex'>
                                    <Checkbox {...labels}
                                        // type='checkbox'
                                        checked={this.state.checkbox}
                                        onChange={this.handleChangeCheckbox}
                                    />
                                    <Typography style={{marginTop:'10px'}}className={classes.check}
                                    >I agree with terms & conditions</Typography>

                                    {this.state.checkbox  ? '' : 
                                        <p style={{
                                            marginLeft: '30px',
                                            marginTop:'22px',
                                            fontSize:'0.75rem',                              
                                            color: 'red',
                                            fontWeight:'400',
                                            lineHeight:'1.66px',
                                            fontFamily:'"Roboto", "Helvetica", "Arial", "sans-serif"'
                                            
                                        }}
                                        FormHelperTextProps={{
                                            className: classes.helperText
                                          }}
                                          >{this.state.errors.checkbox}</p>
                                    }
                                    
                                    </Box>
                                   
                                </Grid>
                                <Grid item xs={12} md={10}
                                 style={{
                                    padding:'6px'
                                }}
                                >

                                    <Button style={{ background: '#007EEF', color: 'white', textTransform: 'capitalize' }} variant='contained' fullWidth onClick={this.handleSignUp}> Create Account</Button>
                                </Grid>
                                <Grid item xs={10}
                                 style={{
                                    padding:'6px'
                                }}
                                >
                                    <Typography
                                        align="center"
                                    >
                                        Already have an account?
                                        <Typography
                                            style={{ paddingLeft: "8px", color: 'blue', cursor: 'grab', textDecoration: 'underline' }}
                                            display='inline'
                                            onClick={() => this.props.history.push("/login")}
                                        >
                                            Sign In
                                        </Typography>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Container>
                    </Grid>
                    <Grid item xs={10} md={5} className={classes.downMdScreenSignUp} >
                        <Grid item className={classes.imageContentSignUp}>
                            <img src={background} className={classes.imgGridSignUp} />
                            <Grid item className={classes.contentImageSignUp}>
                                <Grid container justifyContent='center' spacing={3}>
                                    <Grid item xs={12} md={8}>
                                        <Typography variant='h5' className={classes.imageText2}>Lorem Ipsum dolar</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Typography variant='subtitle1' className={classes.imageSubTextSignUp}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}> <img src={loginImage} className={classes.image3SignUp} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Snackbar
                    open={this.state.snackOpen}
                    autoHideDuration={15000}
                    onClose={this.handleSnakbarClose}
                    message={this.state.toastMessage}
                    action={<Action {...{ handleSnakbarClose: this.handleSnakbarClose }} />}
                />
            </Grid>
        )
        // Customizable Area End
    }
}
// Customizable Area Start
const EmailAccountSignupRouter = withRouter(EmailAccountSignUp)
export default withStyles((theme) =>
    createStyles({
        inputfieledrootSignUp: {
            'label + &': {
                marginTop: 30
            }
        },
        input: {
            borderRadius: '8px',
            position: 'relative',
            backgroundColor: '#FFF',
            fontSize: '16px',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.19,
            letterSpacing: 'normal',
            padding: '14px 16px',
            height:'9px'
        },
        lableroots: {
            fontSize: '13px',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.14,
            letterSpacing: 'normal',
            color: '#6D838C',
        },
        outlinedLabels: {
            transform: 'translate(3px, 9px) scale(1)!important'
        },
       
        imageText2: {
            fontStretch: 'normal',
            fontWeight: "bold",
            fontStyle: 'normal',
            lineHeight: 1.14,
            letterSpacing: 'normal',
            color: '#ffffff',
        },
        helperText:{
            color:'red',
        },
        imageSubTextSignUp: {
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.14,
            letterSpacing: 'normal',
            color: '#ffffff',
        },
        imgGridSignUp: {
            width: "100%",
            height: '100vh',
            [theme.breakpoints.down('md')]: {
                height: "70vh",
            },
        },

        contentSignUp: {
            display: 'grid',
            placeItems: 'center',           
        },
        image3SignUp: {
            width: "200px",
            height: "200px",
            [theme.breakpoints.down('md')]: {
                width: "200px",
                height: "200px",
            },
        },
        contentImageSignUp: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)"
        },
        imageContentSignUp: {
            position: 'relative'
        },
        downMdScreenSignUp: {
            [theme.breakpoints.down('md')]: {
                marginTop: "1rem",
            },
        },
        forgottext: {
            textAlign: "center"
        },
        successdialogtitle: {
            textAlign: "center",
            padding: '20px 23px 0px'
        },
        contextdes: {
            width: '18rem',
            textAlign: 'center',
            marginRight: '0px !important',
            [theme.breakpoints.down('xs')]: {
                width: 'auto'
            },
        },
        dcontent: {
            padding: '8px 24px 24px'
        },
        avatarimg: {
            margin: 'auto',
            width: '50px',
            height: '50px'
        },
        signinlabel: {
            fontSize: '1.2rem',
            fontWeight: 800,
            fontStyle: 'normal',
            position: 'relative',
            left: 0
        },
        signntexts:{
           
            fontSize: '26px',
            fontWeight: 800,
            fontStyle: 'normal',

        },
    })
)(EmailAccountSignupRouter);
// Customizable Area End
