// Customizable Area Start
//@ts-nocheck
import React, { Component } from "react";
import {CssBaseline} from "@material-ui/core";

import { withStyles,} from '@material-ui/core/styles';
import { Route,Switch } from "react-router-dom";
import HeaderWeb from "../../../components/src/HeaderWeb.web"
import SideBarWeb from "../../../components/src/SideBar.web";
import AnalyzeWeb from "./Analyze.web"
import Dashboard from "./Dashboard";
type MyProps = { testID:string, isChecked: boolean;  };
type MyState = {  open:boolean;};
// Customizable Area End



 class DashboardContainer extends Component<MyProps, MyState>{
  constructor(props) {
    super(props);
       // Customizable Area Start
       this.state = {
        open:false,  
     
      };
       // Customizable Area End
    }
setheaderCloseOpen=(value:any)=>{
this.setState({open:value})
}

render() {
  const { classes } = this.props;

return (   
    <div className={classes.root}>
    <CssBaseline />
  <HeaderWeb open={this.state.open}/>
  <SideBarWeb setheaderCloseOpen={this.setheaderCloseOpen}/>
    <main  className={classes.content}>
      <Switch>
        <Route path="/home/analyze" exact>
         <AnalyzeWeb />
        </Route>
        <Route path="/home/dashboard" exact>
         <Dashboard/>
        </Route>
        </Switch>
            </main>    
</div> 
       )
 // Customizable Area End
}
 
}
// Customizable Area Start
const useStyles = theme => ({
  root: {
    display: 'flex',
},
content: {
    flexGrow: 1,
    padding: theme.spacing(5)+1,
}, 
});

export default withStyles(useStyles)(DashboardContainer);
  // Customizable Area End
