// Customizable Area Start
//@ts-nocheck
import React from "react";
import {
Button,
Grid,
 CssBaseline,  TableCell,TableContainer,Paper, Table, TableHead, TableRow, TableBody 
} from "@material-ui/core";
import DashboardPageControllre, {
   Props,
   } from "./DashboardController.web";
   import { createStyles, Theme,withStyles } from '@material-ui/core/styles';
   import { withRouter } from "react-router";

// Customizable Area End

const  createData=(Sno: number, Indicator: string, Assignedto: string, Status: number) =>{
    return { Sno, Indicator, Assignedto, Status };
  }



const rows = [
    createData(1, "403-1 Average hours of training per year per employee", "Chris Medoza", 3),
    createData(2, "401-2 Benefits Provided to full-time employees that are not provided to temporary or part-time employees", "Emilie M", 1),
    createData(3, "403-1 Average hours of training per year per employee", "Chris Medoza", 2),
    createData(4, "403-1 Average hours of training per year per employee", "Chris Medoza", 3),
    createData(5, "401-2 Benefits Provided to full-time employees that are not provided to temporary or part-time employees", "Emilie M", 1),
    createData(6, "403-1 Average hours of training per year per employee", "Chris Medoza", 3),
    createData(7, "403-1 Average hours of training per year per employee", "Chris Medoza", 3),
    createData(8, "401-2 Benefits Provided to full-time employees that are not provided to temporary or part-time employees", "Emilie M", 1),
    createData(9, "403-1 Average hours of training per year per employee", "Chris Medoza", 2),
    createData(10, "403-1 Average hours of training per year per employee", "Chris Medoza", 3),
    createData(11, "403-1 Average hours of training per year per employee", "Chris Medoza", 3),
    createData(12, "401-2 Benefits Provided to full-time employees that are not provided to temporary or part-time employees", "Emilie M", 1),

];
const Tableheading = [
    {
        id: 1,
        heading: "S.no"
    },
    {
        id: 2,
        heading: "Indicator"
    },
    {
        id: 3,
        heading: "Assigned to"
    },
    {
        id: 4,
        heading: "Status"
    }
]
const taskStatusConfig:any=Object.freeze({
  "1":"Completed",
  "2":"In Progress",
  "3":"Started"
})
  const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "rgba(104, 140, 207, 0.12)",
            color: theme.palette.common.black,
            fontWeight: "bold"
        },
        body: {
            fontSize: 14,
        },
    }),
)(TableCell);
  




class DashboardWeb extends DashboardPageControllre {
constructor(props: Props) {
super(props);
   // Customizable Area Start
   // Customizable Area End
}


render() {
     // Customizable Area Start
const { classes } = this.props;

return (   
    <div className={classes.root}>
    <CssBaseline />
    <Grid  className={classes.content}>
               <Grid item xs={12}>
            <Grid container justifyContent='center' alignItems='center'>
                <Grid item xs={10} className={classes.container}>
                    <TableContainer component={Paper} className={classes.paper}>
                        <Table className={classes.table} stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {Tableheading.map((_data) => {
                                        return (<>
                                            <StyledTableCell align='left' key={_data?.id}>{_data?.heading}</StyledTableCell></>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => {
                                    let {Status} = row;

                                    return (
                                    <TableRow key={row?.Sno}>
                                        <StyledTableCell component="th" scope="row"> {row.Sno}</StyledTableCell>
                                        <StyledTableCell align="left">{row.Indicator}</StyledTableCell>
                                        <StyledTableCell align="left" width={"180px"}>{row.Assignedto}</StyledTableCell>
                                        <StyledTableCell align="left">

                                            <Button variant='contained' size='medium' className={classes[this.state.btnClass[Status]]}>
                                            {taskStatusConfig[Status]}
                                            </Button>
                                        </StyledTableCell>
                                    </TableRow>
                                )}
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Grid>
            </Grid>

        </Grid>
             
            </Grid>

    
</div> 
       )
 // Customizable Area End
}
 
}
// Customizable Area Start
const DashboardRouter= withRouter(DashboardWeb)
export default withStyles((theme) =>
createStyles({
    root: {
        display: 'flex',
    },
   
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    

    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    mouse: {
        cursor: "pointer",
    },
    icons: {
        color: "#ffffff"
    },
    flex: {
        flexBasis: "10%"
    },
    headingTxt: {
        color: "#ffffff",
        padding: "0.5rem"
    },
    divider: {
        background: "#ffffff"
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    appDivider: {
        background: "rgba(134, 126, 130, 0.8)",
        height: "15px"
    },
    container: {
        paddingTop:"2rem"
    },
    paper:{
      borderRadius:"10px"
  },
  table: {
    minWidth: 700,
  },
  success:{
    color:"rgba(57, 150, 3, 0.8)",
    backgroundColor:"rgba(215, 255, 191, 0.8)",
    textTransform:"capitalize",
    width:'100%'
  },
  warning:{
    color:"rgba(171, 183, 4, 0.8)",
    backgroundColor:"rgba(239, 245, 160, 0.8)",
    textTransform:"capitalize",
    width:'max-content'
  
  },
  pending:{
    color:"rgba(0, 158, 255, 0.8)",
    backgroundColor:"",
    textTransform:"capitalize",
    width:'100%'
  }   
})
)(DashboardRouter);
// Customizable Area End
