import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");
import { StyledProps } from "@material-ui/core/styles";
import { RouterProps } from "react-router";
export type Props = RouterProps &
  // Customizable Area Start
  StyledProps & {
    id: string;
    navigation: any;
    match: any;
    openToastHandler: any;
    // Customizable Area End
  };



interface S {
  // Customizable Area Start
  open:boolean;
  opensuccess:boolean;
  isSideBarActive:boolean;
  btnClass:any;
  drop:boolean;
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class DashboardPageControllre extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
    //   getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    //   getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      open:false,
      opensuccess:false,
      drop:false,
     
      isSideBarActive:false,
      btnClass:{
        "1":"success",
       "2":"warning",
       "3":"pending" 
           }
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {  
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
    const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId != null) {
          if (responseJson && responseJson.meta && responseJson.meta.token) {
            runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
           
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);       
          }
      if(errorReponse){
        this.parseApiCatchErrorResponse(errorReponse);
      }   
      }
    }
    // Customizable Area End
  }

}
