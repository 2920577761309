import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import { StyledProps } from "@material-ui/core/styles";

export const configJSON = require("./config");
export type Props = RouterProps &
  // Customizable Area Start
  StyledProps & {
    id: string;
    navigation: any;
    match: any;
    openToastHandler: any;
    // Customizable Area End
  };



interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  open:boolean;
  opensuccess:boolean;
  loginObj:any  // Customizable Area End
  errors:any;
  fields:any;
}

interface SS {
  
  id: any;
  
}

export default class EmailAccountLogInController extends BlockComponent<
  Props,
  S,
  SS
> {
  
  apiEmailLoginCallId: string = "";
  signInApiCallId: string = "";
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
    
      getName(MessageEnum.RestAPIResponceMessage),
    
    ];

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      open:false,
      opensuccess:false,
      loginObj:{},
      errors: {
        username : '',
        password: '',
    },
    fields: {
      username : "",
      password: ""
  }
    };


    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {  
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));  
  }
  async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId != null) {
        if(apiRequestCallId === this.signInApiCallId){
          this.handleLoginApiResponses(responseJson);
        }
        if (apiRequestCallId === this.apiEmailLoginCallId) {
          if (responseJson && responseJson.meta && responseJson.meta.token) {
            runEngine.unSubscribeFromMessages(this, this.subScribedMessages);           
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);        
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }
    // Customizable Area End
  }
  handleClose = () => {
    this.setState({open:false});
  };
 handleOpen = () => {
  this.setState({open:true});
  };
closeForgorPopup=()=>{
  this.setState({open:false,opensuccess:true});
 }
 handleSuccessClose=()=>{
  this.setState({opensuccess:false});
  }
  handleLogin=()=>{   
    const { fields } = this.state;
    let validationErrors:any = {};
    Object.keys(fields).forEach(name => {
        const error = this.validate(name, fields[name]);
        if (error && error.length > 0) {
            validationErrors[name] = error;
        }            
    });
    if (Object.keys(validationErrors).length > 0) {
        this.setState({ errors: validationErrors });
        return;
    }
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.signInApiCallId = requestMessage.messageId;
    const httpBody = { 
        "data": {
            "type": "email_account",
            "attributes": {
                "email": this.state.loginObj.username,
                "password": this.state.loginObj.password,         
            }
        } 
    } 
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signinUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleLoginApiResponses=(responseJson:any)=>{
    console.log(responseJson)
    if (responseJson && responseJson.meta) {
      console.log(responseJson)
      this.props.history.push("/home")
    }
  };

  validate = (name:any, value:any) => {
    switch (name) {
        case "username":
            if (!value) {
            return "Email is Required";
            } else if (
            !value.match(/^[a-z0-9]([a-z0-9_\-\.]*)@([a-z0-9_\-\.]*)(\.[a-z]{2,4}(\.[a-z]{2}){0,2})$/i)
            ) {
            return "Enter a valid email address";
            } else {
            return "";
            }
        case "password":
            return this.handlePasswordValidationLog(value);
        default: {
            return "";
        }
    }
}
handlePasswordValidationLog=(value:any)=>{
  if (!value) {
    return "Password is Required";
    } else if (value.length < 8 || value.length > 15) {
    return "Please fill at least 8 character";
    } else if (!value.match(/[a-z]/g)) {
    return "Please enter at least lower character.";
    } else if (!value.match(/[A-Z]/g)) {
    return "Please enter at least upper character.";
    } else if (!value.match(/\d/g)) {
    return "Please enter at least one digit.";
    } else {
    return "";
    }
}


}
