// Customizable Area Start
//@ts-nocheck
import React,{Component} from "react";
import {
Typography,
Grid,
Avatar,Drawer, List, Divider, ListItem, ListItemIcon, ListItemText, 
} from "@material-ui/core";
   import { createStyles,withStyles } from '@material-ui/core/styles';
   import { withRouter } from "react-router";
import { SettingsInputCompositeOutlined, GpsFixed, AssessmentOutlined, PieChart, AccountTreeOutlined, SpeedOutlined, ViewQuilt, SettingsOutlined, } from '@material-ui/icons';
import clsx from 'clsx';
type MyProps = { testID:string, isChecked: boolean; setheaderCloseOpen?: (value: boolean) => void };
type MyState = { isSideBarActive: boolean; open:boolean;};
// Customizable Area End

const drawerWidth = 240;
const sectionOne = [
    {
        id: 1,
        icon: <ViewQuilt />,
        text: "Sustainext HQ",
        path:'/home/dashboard'

    },
    {
        id: 2,
        icon: <SpeedOutlined />,
        text: "Materiality Dashboard",
        path:'/home'

    }
]
const sectionTwo = [
    {
        id: 1,
        icon: <AccountTreeOutlined />,
        text: "Collect",
        path: '/home'

        
    },
    {
        id: 2,
        icon: <PieChart />,
        text: "Analyze",
        path:'/home/analyze'

    },
    {
        id: 3,
        icon: <AssessmentOutlined />,
        text: "Reports",
        path: '/home'
    },
    {
        id: 4,
        icon: <SettingsInputCompositeOutlined />,
        text: "Optmize",
        path:'/home'
    },
    {
        id: 5,
        icon: <GpsFixed />,
        text: "Track",
        path:'/home'

    }
]
const sectionThree = [
    {
        id: 1,
        icon: <SettingsOutlined />,
        text: "General"
    },

]

const logo=require("./logo.png")

class SideBar extends Component<MyProps, MyState>  {
constructor(props: Props) {
super(props);
   // Customizable Area Start
   this.state = {
    open:true,  
    isSideBarActive:true,
 
  };
   // Customizable Area End
}


render() {
     // Customizable Area Start
const { classes } = this.props;
const { open }=this.state

return (   
    <Drawer
    variant="permanent"
    className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
    })}
    classes={{
        paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
        }),
    }}
>
    <Grid item xs={12} className={classes.flex} >
        <Grid container justifyContent='center' alignItems='center' className={classes.section}>
            {!this.state.isSideBarActive ?
                <Grid item >
                    <Avatar src={logo}  onClick={()=>this.handleDrawerOpens()} className={classes.mouse} />
                </Grid> :
                <Grid item >
                    <ListItem button onClick={()=>this.handleDrawerCloses()}>
                        <ListItemIcon><Avatar src={logo} /></ListItemIcon>
                        <ListItemText primary={this.state.isSideBarActive ?"Sustainext": null} className={classes.icons} />
                    </ListItem>
                </Grid>
            }
        </Grid>
    </Grid>
    {this.state.isSideBarActive ?<Grid item>
        <Typography align='left' className={classes.headingTxt}>SECTION 1</Typography>
        <Divider className={classes.divider} />
    </Grid> : null}

    <List>
        {sectionOne.map((_data, index) => (
            <ListItem button key={_data?.id}  onClick={()=>this.navagateToPage(_data)}>
                <ListItemIcon className={classes.icons}>{_data?.icon}</ListItemIcon>
                <ListItemText primary={this.state.isSideBarActive ?  _data?.text:null} className={classes.icons} />
            </ListItem>
        ))}
    </List>
    {this.state.isSideBarActive ? <Grid item>
        <Typography align='left' className={classes.headingTxt}>SECTION 2</Typography>
    </Grid> : null}
    <Divider className={classes.divider} />
    <List>
        {sectionTwo.map((_data, index) => (
            <ListItem button key={_data?.id} onClick={()=>this.navagateToPage(_data)}>
                <ListItemIcon className={classes.icons}>{_data?.icon}</ListItemIcon>
                <ListItemText primary={this.state.isSideBarActive ? _data?.text: null} className={classes.icons} />
            </ListItem>
            
        ))}
       
    </List>
    {this.state.isSideBarActive  ? <Grid item>
        <Typography align='left' className={classes.headingTxt}>SECTION 3</Typography>
    </Grid> : null}
    <Divider className={classes.divider} />
    <List>
        {sectionThree.map((_data) => (
            <ListItem button key={_data?.id} onClick={()=>this.navagateToPage(_data)}>
                <ListItemIcon className={classes.icons}>{_data?.icon}</ListItemIcon>
                <ListItemText primary={this.state.isSideBarActive ? _data?.text: null} className={classes.icons} />
            </ListItem>
        ))}
    </List>
</Drawer>

       )
 // Customizable Area End
}
handleDrawerOpens = () => {
    this.setState({open:true, isSideBarActive:true})
    this.props.setheaderCloseOpen(false)
};
 handleDrawerCloses= () => {
    this.setState({open:false, isSideBarActive:false})
    this.props.setheaderCloseOpen(true)
};
navagateToPage=(obj:any)=>{
this.props.history.push(obj.path)
};

 
}
// Customizable Area Start
const SideBarRouter= withRouter(SideBar)
export default withStyles((theme) =>
createStyles({
    root: {
        display: 'flex',
    },
    appBar: {
        width: `calc(100% - ${theme.spacing(8) + 1})`,
        marginLeft: drawerWidth,
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        
        background: 'linear-gradient(#007EEF, #007EEF, #03B0F7, #06E1FF )',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
       
        background:'linear-gradient(#007EEF, #007EEF, #03B0F7, #06E1FF )',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(8) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    appBarOpen: {
        background: "rgba(242, 218, 230, 0.03)",
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    appbarClose: {
        background: "rgba(242, 218, 230, 0.03)",
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: `calc(100% - ${theme.spacing(9)}px)`,
    },

    contents: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    mouse: {
        cursor: "pointer",
    },
    icons: {
        color: "#ffffff"
    },
    flex: {
        flexBasis: "10%"
    },
    headingTxt: {
        color: "#ffffff",
        padding: "0.5rem"
    },
    divider: {
        background: "#ffffff"
    },
    section:{
        background:"#2470D5",
        width: '100%',
        height: '63px',
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    appDivider: {
        background: "rgba(134, 126, 130, 0.8)",
        height: "15px"
    },
    container: {
        paddingTop:"2rem"
    },
    paper:{
      borderRadius:"10px"
  },
  table: {
    minWidth: 700,
  },
  success:{
    color:"rgba(57, 150, 3, 0.8)",
    backgroundColor:"rgba(215, 255, 191, 0.8)",
    textTransform:"lowercase"
  },
  warning:{
    color:"rgba(171, 183, 4, 0.8)",
    backgroundColor:"rgba(239, 245, 160, 0.8)",
    textTransform:"lowercase"
  
  },
  pending:{
    color:"rgba(0, 158, 255, 0.8)",
    backgroundColor:"",
    textTransform:"lowercase"
  }   
})
)(SideBarRouter);
// Customizable Area End
