// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import RolesAndPermission42 from "../../blocks/RolesAndPermission42/src/RolesAndPermission42";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import VideoLibrary from "../../blocks/VideoLibrary/src/VideoLibrary";
import TaskAllocator from "../../blocks/TaskAllocator/src/TaskAllocator";
import TaskViews from "../../blocks/TaskViews/src/TaskViews";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import SaveAsPdf from "../../blocks/saveaspdf/src/SaveAsPdf";
import BlogPostsManagement from "../../blocks/BlogPostsManagement/src/BlogPostsManagement";
import DataImportexportcsv from "../../blocks/DataImportexportcsv/src/DataImportexportcsv";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import HelpdeskReporting from "../../blocks/HelpdeskReporting/src/HelpdeskReporting";
import Testimonials from "../../blocks/Testimonials/src/Testimonials";
import Download from "../../blocks/Download/src/Download";
import AnimationsAndTransition3 from "../../blocks/AnimationsAndTransition3/src/AnimationsAndTransition3";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import Videos from "../../blocks/videos/src/Videos";
import PeopleManagement2 from "../../blocks/PeopleManagement2/src/PeopleManagement2";
import RequestDemo from "../../blocks/RequestDemo/src/RequestDemo";
import Customform from "../../blocks/customform/src/Customform";
import TicketSystem from "../../blocks/TicketSystem/src/TicketSystem";
import FreeTrial from "../../blocks/FreeTrial/src/FreeTrial";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Notifications from "../../blocks/notifications/src/Notifications";
import Crm3rdPartyIntegration from "../../blocks/Crm3rdPartyIntegration/src/Crm3rdPartyIntegration";
import Chatbot6 from "../../blocks/Chatbot6/src/Chatbot6";
import Analytics from "../../blocks/analytics/src/Analytics";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import LinkShare from "../../blocks/LinkShare/src/LinkShare";
import OnlineBackup from "../../blocks/OnlineBackup/src/OnlineBackup";
import FormApprovalWorkflow from "../../blocks/FormApprovalWorkflow/src/FormApprovalWorkflow";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import CfQlikviewIntegration from "../../blocks/CfQlikviewIntegration/src/CfQlikviewIntegration";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import ManageBlogComments from "../../blocks/ManageBlogComments/src/ManageBlogComments";
import BulkUploading from "../../blocks/BulkUploading/src/BulkUploading";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import FrontendWebsiteEmissionCalculator2 from "../../blocks/FrontendWebsiteEmissionCalculator2/src/FrontendWebsiteEmissionCalculator2";
import ProjectTaskTracking from "../../blocks/ProjectTaskTracking/src/ProjectTaskTracking";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import TermsAndConditions from "../../blocks/TermsAndConditions/src/TermsAndConditions";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Sorting from "../../blocks/sorting/src/Sorting";
import MultipageForms2 from "../../blocks/MultipageForms2/src/MultipageForms2";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import DownloadOptions from "../../blocks/DownloadOptions/src/DownloadOptions";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import TaskGroups from "../../blocks/TaskGroups/src/TaskGroups";
import ElasticSearch from "../../blocks/ElasticSearch/src/ElasticSearch";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import LandingPage22 from "../../blocks/LandingPage22/src/LandingPage22";
import CfCarbonCalculator2 from "../../blocks/CfCarbonCalculator2/src/CfCarbonCalculator2";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";

import EmailAccountSignUp from "../../blocks/email-account-login/src/EamilAccountSignUp.web"
import EmailAccountLogin from "../../blocks/email-account-login/src/EmailAccountLogin.web"
import DashboardWeb from "../../blocks/dashboard/src/Dashboard.web"
import AnalyzeWeb from "../../blocks/dashboard/src/Analyze.web"
import RouterConfig from './route.config'
const routeMap = {
RolesAndPermission42:{
 component:RolesAndPermission42,
path:"/RolesAndPermission42"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
VideoLibrary:{
 component:VideoLibrary,
path:"/VideoLibrary"},
TaskAllocator:{
 component:TaskAllocator,
path:"/TaskAllocator"},
TaskViews:{
 component:TaskViews,
path:"/TaskViews"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
SaveAsPdf:{
 component:SaveAsPdf,
path:"/SaveAsPdf"},
BlogPostsManagement:{
 component:BlogPostsManagement,
path:"/BlogPostsManagement"},
DataImportexportcsv:{
 component:DataImportexportcsv,
path:"/DataImportexportcsv"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
HelpdeskReporting:{
 component:HelpdeskReporting,
path:"/HelpdeskReporting"},
Testimonials:{
 component:Testimonials,
path:"/Testimonials"},
Download:{
 component:Download,
path:"/Download"},
AnimationsAndTransition3:{
 component:AnimationsAndTransition3,
path:"/AnimationsAndTransition3"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
RolesPermissions2:{
 component:RolesPermissions2,
path:"/RolesPermissions2"},
Videos:{
 component:Videos,
path:"/Videos"},
PeopleManagement2:{
 component:PeopleManagement2,
path:"/PeopleManagement2"},
RequestDemo:{
 component:RequestDemo,
path:"/RequestDemo"},
Customform:{
 component:Customform,
path:"/Customform"},
TicketSystem:{
 component:TicketSystem,
path:"/TicketSystem"},
FreeTrial:{
 component:FreeTrial,
path:"/FreeTrial"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
Crm3rdPartyIntegration:{
 component:Crm3rdPartyIntegration,
path:"/Crm3rdPartyIntegration"},
Chatbot6:{
 component:Chatbot6,
path:"/Chatbot6"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
LinkShare:{
 component:LinkShare,
path:"/LinkShare"},
OnlineBackup:{
 component:OnlineBackup,
path:"/OnlineBackup"},
FormApprovalWorkflow:{
 component:FormApprovalWorkflow,
path:"/FormApprovalWorkflow"},
AdminConsole3:{
 component:AdminConsole3,
path:"/AdminConsole3"},
CfQlikviewIntegration:{
 component:CfQlikviewIntegration,
path:"/CfQlikviewIntegration"},
Settings5:{
 component:Settings5,
path:"/Settings5"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
ManageBlogComments:{
 component:ManageBlogComments,
path:"/ManageBlogComments"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
FrontendWebsiteEmissionCalculator2:{
 component:FrontendWebsiteEmissionCalculator2,
path:"/FrontendWebsiteEmissionCalculator2"},
ProjectTaskTracking:{
 component:ProjectTaskTracking,
path:"/ProjectTaskTracking"},
ContentManagement:{
 component:ContentManagement,
path:"/ContentManagement"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
TermsAndConditions:{
 component:TermsAndConditions,
path:"/TermsAndConditions"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
MultipageForms2:{
 component:MultipageForms2,
path:"/MultipageForms2"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
DownloadOptions:{
 component:DownloadOptions,
path:"/DownloadOptions"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
TaskGroups:{
 component:TaskGroups,
path:"/TaskGroups"},
ElasticSearch:{
 component:ElasticSearch,
path:"/ElasticSearch"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
LandingPage22:{
 component:LandingPage22,
path:"/LandingPage22"},
CfCarbonCalculator2:{
 component:CfCarbonCalculator2,
path:"/CfCarbonCalculator2"},
EmailNotifications:{
 component:EmailNotifications,
path:"/EmailNotifications"},

  // Home: {
  //   component: HomeScreen,
  //   path: '/',
  //   exact: true
  // },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
SignUp:{
  component: EmailAccountSignUp,
    path: '/',
    exact: true
},
SignIn:{
  component: EmailAccountLogin,
    path: '/login',
    exact: true
},
DashboardWeb:{
  component:DashboardWeb,
 path:"/DashboardPage"
},
 AnalyzeWeb:{
  component:AnalyzeWeb,
 path:"/AnalyzePage"
},



};


const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
   
    return (
        <div>
        <RouterConfig />
        </div>
       
    );
  }
}

export default App;